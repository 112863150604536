<template>
  <div>
    <div class="loading" v-if="isLoading == true">
      <div class="sk-three-bounce">
        <div class="sk-child sk-bounce1"></div>
        <div class="sk-child sk-bounce2"></div>
        <div class="sk-child sk-bounce3"></div>
      </div>
    </div>
    <div class="animated fadeIn">
      <b-form @submit.prevent="handlePreSubmit" novalidate>
        <b-card-header>
          Add User Army
          <div class="card-header-actions">
            <a
              class="card-header-action"
              href="user-army"
              rel="noreferrer noopener"
            >
              <small class="text-muted">Back</small>
            </a>
          </div>
        </b-card-header>
        <b-card>
          <b-row class="mb-4">
            <!-- Email User -->
            <b-col class="mb-4" sm="12">
              <h6>Email</h6>
              <b-form-group id="userEmail" label-for="email">
                <b-form-input
                  id="email"
                  type="text"
                  size="md"
                  v-model="form.email"
                  aria-describedby="userEmail"
                  placeholder="Input email user"
                  autocomplete="given-name"
                  @input="handleClearError('email_user')"
                  :class="{
                    'is-invalid':
                      $v.form.email.$error || errors.messageInputs.email_user
                  }"
                >
                </b-form-input>
                <span
                  class="text-danger"
                  v-show="!$v.form.email.required && $v.form.email.$dirty"
                  >Email cannot be empty</span
                >
                <span
                  class="text-danger"
                  v-show="!$v.form.email.validEmail && $v.form.email.$dirty"
                  >Email must be valid</span
                >
                <span
                  class="text-danger"
                  v-show="
                    errors.messageInputs && errors.messageInputs.email_user
                  "
                >
                  {{ errors.messageInputs.email_user }}
                </span>
              </b-form-group>
            </b-col>
            <!-- RefCode User -->
            <b-col class="mb-4" sm="12">
              <h6>Referral Code User</h6>
              <b-form-group id="ref-code-user" label-for="ref-code">
                <b-form-input
                  id="ref-code"
                  type="text"
                  size="md"
                  v-model="form.refCode"
                  aria-describedby="ref-code-user"
                  placeholder="Input referral code user"
                  autocomplete="given-name"
                  @input="handleClearError('referral_code_user')"
                  :class="{
                    'is-invalid':
                      $v.form.refCode.$error ||
                      errors.messageInputs.referral_code_user
                  }"
                >
                </b-form-input>
                <span
                  class="text-danger"
                  v-show="!$v.form.refCode.required && $v.form.refCode.$dirty"
                  >Referreal Code cannot be empty</span
                >
                <span
                  class="text-danger"
                  v-show="
                    errors.messageInputs &&
                      errors.messageInputs.referral_code_user
                  "
                >
                  {{ errors.messageInputs.referral_code_user }}
                </span>
              </b-form-group>
            </b-col>
            <!-- Email Chief -->
            <b-col class="mb-4" sm="12">
              <h6>Email Chief</h6>
              <b-form-group id="user-email-chief" label-for="email-chief">
                <b-form-input
                  id="email-chief"
                  type="text"
                  size="md"
                  v-model="form.emailChief"
                  aria-describedby="user-email-chief"
                  placeholder="Input email chief"
                  autocomplete="given-name"
                  @input="handleClearError('email_chief')"
                  :class="{
                    'is-invalid':
                      $v.form.emailChief.$error ||
                      errors.messageInputs.email_chief
                  }"
                >
                </b-form-input>
                <span
                  class="text-danger"
                  v-show="
                    !$v.form.emailChief.required && $v.form.emailChief.$dirty
                  "
                  >Email Chief cannot be empty</span
                >
                <span
                  class="text-danger"
                  v-show="
                    !$v.form.emailChief.validEmail && $v.form.emailChief.$dirty
                  "
                  >Email Chief must be valid</span
                >
                <span
                  class="text-danger"
                  v-show="
                    errors.messageInputs && errors.messageInputs.email_chief
                  "
                >
                  {{ errors.messageInputs.email_chief }}
                </span>
              </b-form-group>
            </b-col>
            <!-- RefCode Chief -->
            <b-col class="mb-4" sm="12">
              <h6>Referral Code Chief</h6>
              <b-form-group id="ref-code-chief" label-for="ref-code-chief">
                <b-form-input
                  id="ref-code-chief"
                  type="text"
                  size="md"
                  v-model="form.refCodeChief"
                  aria-describedby="ref-code-chief"
                  placeholder="Input referral code chief"
                  autocomplete="given-name"
                  @input="handleClearError('referral_code_chief')"
                  :class="{
                    'is-invalid':
                      $v.form.refCodeChief.$error ||
                      errors.messageInputs.referral_code_chief
                  }"
                >
                </b-form-input>
                <span
                  class="text-danger"
                  v-show="
                    !$v.form.refCodeChief.required &&
                      $v.form.refCodeChief.$dirty
                  "
                  >Referral Code Chief cannot be empty</span
                >
                <span
                  class="text-danger"
                  v-show="
                    errors.messageInputs &&
                      errors.messageInputs.referral_code_chief
                  "
                >
                  {{ errors.messageInputs.referral_code_chief }}
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-button type="submit" variant="primary">
            Submit
          </b-button>
        </b-card>
      </b-form>
      <b-modal
        id="modal-center"
        v-if="this.modal"
        size="sm"
        v-model="modal"
        @ok="handleModal"
        centered
        hide-header
        hide-footer
        style="width: 340px;"
      >
        <b-row class="p-4">
          <b-col
            class="d-flex align-items-center justify-content-center flex-column"
          >
            <div class="text-center mb-4">
              <h3 class="mb-2">Are You Sure?</h3>
              <p>Make sure the data is correct</p>
            </div>
            <div class="button-actions d-flex" style="gap: 8px;">
              <b-button
                variant="outline-secondary"
                style="width: 113px; height: 38px; color: #3B353C;"
                @click="handleModal()"
                >Back</b-button
              >
              <b-button
                variant="primary"
                style="width: 113px; height: 38px;"
                @click="onSubmit()"
                >Confirm</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { validationMixin } from "vuelidate";
import { required, helpers } from "vuelidate/lib/validators";
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const validEmail = helpers.regex("validateEmail", emailRegex);

export default {
  name: "add-user-army",
  components: {},
  mixins: [validationMixin],
  validations: {
    form: {
      email: {
        required,
        validEmail
      },
      refCode: {
        required
      },
      emailChief: {
        required,
        validEmail
      },
      refCodeChief: {
        required
      }
    }
  },
  computed: {
    formStr() {
      return JSON.stringify(this.form, null, 4);
    },
    isValid() {
      return !this.$v.form.$anyError;
    },
    isDirty() {
      return this.$v.form.$anyDirty;
    },
    invCheck() {
      return "You must accept before submitting";
    }
  },
  data() {
    return {
      data: {},
      form: {
        email: null,
        refCode: null,
        emailChief: null,
        refCodeChief: null
      },
      isLoading: false,
      warning_message: true,
      errors: {
        code: "",
        message: "",
        messageInputs: "",
        status: ""
      },
      modal: false
    };
  },
  created() {
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("access_token")}`;
  },
  methods: {
    chkState(val) {
      const field = this.$v.form[val];
      return !field.$dirty || !field.$invalid;
    },
    findFirstError(component = this) {
      if (component.state === false) {
        if (component.$refs.input) {
          component.$refs.input.focus();
          return true;
        }
        if (component.$refs.check) {
          component.$refs.check.focus();
          return true;
        }
      }
      let focused = false;
      component.$children.some(child => {
        focused = this.findFirstError(child);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.findFirstError());
      return this.isValid;
    },
    handlePreSubmit() {
      this.$v.$touch();
      if (!this.$v.$invalid) this.handleModal();
    },
    onSubmit() {
      this.isLoading = true;
      this.handleModal();

      const requestBody = {
        email_user: this.form.email,
        referral_code_user: this.form.refCode,
        email_chief: this.form.emailChief,
        referral_code_chief: this.form.refCodeChief
      };

      this.$http
        .post(`army/users`, requestBody)
        .then(res => {
          this.isLoading = false;
          this.$swal
            .fire("Success!", `${res.data.meta.message}`, "success")
            .then(() => {
              this.$router.push({ name: "User Army" });
            });
        })
        .catch(error => {
          const err = error.response;

          if (err) {
            this.isLoading = false;
            this.errors.code = err.status;
            this.errors.message = err.data.meta.message;
            this.errors.messageInputs = err.data.data;
            this.errors.status = err.data.meta.status;

            if (this.errors.status === 404) {
              this.$swal.fire("Failed!", `${res.data.meta.message}`, "error");
            } else {
              this.$swal.fire("Failed!", this.errors.message, "error");
            }
          }
        });
    },
    handleModal() {
      this.modal = !this.modal;
    },
    handleClearError(val) {
      const newMessage = this.errors?.messageInputs;
      delete newMessage[val];
      this.errors.messageInputs = newMessage;
    }
  }
};
</script>

<style>
.submt {
  width: 100%;
  height: auto;
  display: block;
}
</style>
<style>
.is-invalid {
  border-color: red;
}

.btn.disabled {
  background: #dfdfdf !important;
  color: grey !important;
  border-color: #dfdfdf !important;
}

.form-control {
  height: 36.22px;
}

.text-red {
  color: #cb211f;
}
.text-success {
  color: #35aa4b;
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
}
</style>
<style src="spinkit/scss/spinkit.scss" lang="scss" />
